import React from "react"
import "../styles/album.css"
import { FaFacebook, FaGlobe, FaLinkedinIn, FaEnvelope } from "react-icons/fa"

class BrokerListing extends React.Component {
  getBrokerList() {
    const brokerList = []

    this.props.brokerNodes.forEach(brokerNode => {
      brokerList.push({
        key: brokerNode.recordId,
        name: brokerNode.data.Name,
        bio: (brokerNode.data.Bio || "No bio...").substring(0, 280),
        title:
          brokerNode.data.Position +
          " at " +
          brokerNode.data.Company[0].data.Name,
        pic: brokerNode.data.Picture[0].url,
        website: brokerNode.data.Website,
        facebook: brokerNode.data.Facebook,
        linkedin: brokerNode.data.LinkedIn,
        email: brokerNode.data.Email,
      })
    })
    return brokerList
  }
  render() {
    const brokerList = this.getBrokerList()
    return (
      <div className="container">
        {brokerList.map(broker => (
          <div className="row justify-content-between" key={broker.key}>
            <div className="col-lg-12 ">
              <div className="card card-article-wide shadow-sm flex-md-row no-gutters border-soft mb-4 animate-up-5">
                <a href="single-space.html" className="col-md-3 col-lg-3">
                  <img
                    src={broker.pic}
                    alt=""
                    className="card-img-top space-image-lg"
                  ></img>
                </a>
                <div className="card-body d-flex flex-column justify-content-between col-auto p-4">
                  <a href="single-space.html">
                    <h4 className="font-weight-normal mb-0">{broker.name}</h4>
                  </a>
                  <div className="post-meta">
                    <span className="small lh-120">{broker.title}</span>
                  </div>

                  <div className="d-flex my-4">
                    <p className="card-text">{broker.bio}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <ul className="social-buttons list-style-none d-flex">
                      {broker.website && (
                        <li className="mr-3">
                          <a
                            href={broker.website}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-lg btn-link btn-facebook text-facebook"
                          >
                            <i
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Visit website"
                              data-original-title="Visit website"
                            >
                              <FaGlobe />
                            </i>
                          </a>
                        </li>
                      )}
                      {broker.linkedin && (
                        <li className="mr-3">
                          <a
                            href={broker.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-lg btn-link btn-linkedin text-linkedin"
                          >
                            <i
                              className="fab fa-linkedin"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="View LinkedIn profile"
                              data-original-title="View LinkedIn profile"
                            >
                              <FaLinkedinIn />
                            </i>
                          </a>
                        </li>
                      )}
                      {broker.facebook && (
                        <li className="mr-3">
                          <a
                            href={broker.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-lg btn-link btn-facebook text-facebook"
                          >
                            <i
                              className="fab fa-facebook"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="View Facebook page"
                              data-original-title="View Facebook page"
                            >
                              <FaFacebook />
                            </i>
                          </a>
                        </li>
                      )}
                      {broker.email && (
                        <li className="mr-3">
                          <a
                            role="button"
                            href={"mailto:" + broker.email}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-lg btn-link btn-facebook text-facebook"
                          >
                            <i
                              className="fab fa-facebook"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Send email"
                              data-original-title="Send email"
                            >
                              <FaEnvelope />
                            </i>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default BrokerListing

// < div className = "col-md-4" key = { broker.key } >
//   <div className="card mb-4 box-shadow">
//     <img className="card-img-top" src={broker.pic} alt="hello" />
//     <div className="card-body">
//       <h5 className="card-title">{broker.name}</h5>
//       <h6 className="card-subtitle mb-2 text-muted">
//         {broker.title}
//       </h6>
//       <p className="card-text">{broker.bio}</p>

//     </div>
//   </div>
//             </div >
