import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import BrokerListing from "../components/BrokerListing"
import Footer from "../components/Footer"

export default ({ data, location }) => (
  <Layout>
    <main>
      <section
        className="section section-lg bg-secondary overlay-dark text-white"
        style={{ backgroundImage: `url("../blog-hero.jpg")` }}
      >
        <div className="container">
          <div className="row justify-content-center pt-5">
            <div className="col-12">
              <h1 className="display-2">
                Top brokers in{" "}
                <span className="font-weight-light">
                  {data.region.nodes[0].data.Name}
                </span>
              </h1>
              <p className="lead text-muted mt-4">
                This is a currated list of the top morgage brokers in the state.
                We analyse data from multiple sources to provide you with the
                highest quality trustworthy, expert brokers.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="section section-md pt-5">
        <div className="container">
          <div className="row justify-content-between"></div>
          {/* Post Listings */}
          <div className="section section-md pt-5">
            <BrokerListing brokerNodes={data.brokers.nodes} />
          </div>
        </div>
      </div>
    </main>
    <Footer />
  </Layout>
)

export const query = graphql`
  query GetBrokersByRegion($slug: String) {
    brokers: allAirtable(
      filter: {
        data: { Region: { elemMatch: { data: { Slug: { eq: $slug } } } } }
      }
      sort: { fields: data___Rank, order: DESC }
      limit: 9
    ) {
      nodes {
        recordId
        data {
          Name
          Bio
          Position
          Website
          LinkedIn
          Facebook
          Email
          Company {
            data {
              Name
            }
          }
          Picture {
            url
          }
        }
      }
    }
    region: allAirtable(
      filter: { table: { eq: "Regions" }, data: { Slug: { eq: $slug } } }
    ) {
      nodes {
        recordId
        data {
          Name
        }
      }
    }
  }
`
